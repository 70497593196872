@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&family=Roboto:wght@100;300;400;700;900&family=Ubuntu+Mono:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app{
  height: 100%;
  background-color:#000000;
  @apply text-white;
}
.bg-black {
  border: #b9a874 4px  double;
}
.top-0{
  top: 1px;
}
.navbar123 {
  margin-left: -20px !important;
  width: 20% !important;
}
.shadow-custom {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  /* Adjust the values above to change the shadow effect */
}

.font-opensans {
  font-size: 20px;
}
.p-4 {
  padding: 1rem;
  padding-left: 2rem;
}
.img1{
  float: left;
    margin-left: -115px;
    margin-bottom: 100px;

}

.text2{
  margin-left: 100px;
}

.roundedshape{
  margin-left: -20px;  
}
.margin-left-x{
  margin-left: 100px;
  margin-right: 100px;
}
.margin-tb{
  margin-top: 0px;
  margin-bottom: 0px;
}
.padding-bt{
  padding-bottom: 300px;
  padding-top: 0px
}
.margin-bl{
  margin-bottom: 190px;
}
.logo-1{
  margin-top: -120px;
}

.mysection {
padding: 20px;
}

.heading23 {
  font-size: 45px !important;
  margin-bottom: 30px;
}

.marlr{
  margin-right: 120px;
  margin-left:120px;
}

#companies {
margin-top: -50px;
}

a:hover{
color: #b9a874 !important;
transition: 2s;
}
.flip-card {
  background-color: transparent;
  width: 300px; /* Set the width of the card */
  height: 400px; /* Set the height of the card */
  perspective: 1000px; /* Perspective for 3D effect */
  display: inline-block;
  margin: 15px; /* Adjust margin as needed */
  cursor: pointer;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.7s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(-180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front {
  /* Style for front side of the card */
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flip-card-front h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.flip-card-front p {
  font-size: 1rem;
  text-align: center;
}

.flip-card-back {
  /* Style for back side of the card */
  background-color: black;
  color: #fff;
  padding: 10px;
  transform: rotateY(-180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flip-card-back p {
  font-size: 0.7rem;
  text-align: center;
}
.flip-card1 {
  background-color: transparent;
  width: 300px; /* Set the width of the card */
  height: 400px; /* Set the height of the card */
  perspective: 1000px; /* Perspective for 3D effect */
  display: inline-block;
  margin: 20px; /* Adjust margin as needed */
  cursor: pointer;
}

.flip-card-inner1 {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.7s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card:hover .flip-card-inner1 {
  transform: rotateY(-180deg);
}

.flip-card-front1,
.flip-card-back1 {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front1 {
  /* Style for front side of the card */
  background-color: #000;
  color: #b9a874;
  border: 3px solid #b9a874;
  border-radius: 40px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flip-card-front1 h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.flip-card-front1 p {
  font-size: 1rem;
  text-align: center;
}

.flip-card-back1 {
  /* Style for back side of the card */
  background-color: black;
  color: #fff;
  padding: 10px;
  transform: rotateY(-180deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flip-card-back1 p {
  font-size: 0.7rem;
  text-align: center;
}

.borderteam{
  border: 4px double #b9a874;
}

@media (max-width: 768px) {
  .hide-border {
    border: none;
  }
}